(function () {
  'use strict';

  /* @ngdoc object
   * @name amigoSecreto
   * @description
   *
   */
  angular
    .module('amigoSecreto', [
      'ngMaterial',
      'ui.router',
      'home'
    ]);
}());
